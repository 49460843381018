import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { TokenStorageService } from 'src/app/theme/utils/token-storage.service';
import { AuthService } from '../../utils/auth.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  public isUserAuthenticated: boolean;
  constructor(private _authService: AuthService, private _router: Router, private token: TokenStorageService) { }

  ngOnInit() {
    const token = this.token.getToken();
    if (token != undefined || token != null) {
      this.isUserAuthenticated = true;
    }
    else
      this.isUserAuthenticated = false;
  }

  openMegaMenu() {
    let pane = document.getElementsByClassName('cdk-overlay-pane');
    [].forEach.call(pane, function (el) {
      if (el.children.length > 0) {
        if (el.children[0].classList.contains('mega-menu')) {
          el.classList.add('mega-menu-pane');
        }
      }
    });
  }

}
