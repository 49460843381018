import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../../../app.service';
import { Settings, AppSettings } from '../../../app.settings';
import { Router } from '@angular/router';
import { TokenStorageService } from 'src/app/theme/utils/token-storage.service';
import { AuthService } from 'src/app/theme/utils/auth.service';
@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html'
})
export class TopMenuComponent implements OnInit {
  public isUserAuthenticated: boolean;
  public userInfo: any = [];
  public currencies = ['USD', 'EUR'];
  public currency: any;

  public settings: Settings;
  constructor(public appSettings: AppSettings, public appService: AppService, public translateService: TranslateService,
    private _authService: AuthService, private _router: Router, private token: TokenStorageService) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit() {
    this.currency = this.currencies[0];
    const token = this.token.getToken();
    if (token != undefined || token != null) {
      this.isUserAuthenticated = true;
      this.getInfo();
    }
    else
      this.isUserAuthenticated = false;
  }

  public getInfo() {
    this.appService.getUserById("api/User/GetUserById").subscribe(data => {
      this.userInfo = data;
    });
  }

  public changeCurrency(currency) {
    this.currency = currency;
  }

  public changeLang(lang: string) {
    this.translateService.use(lang);
  }

  public logout = () => {

    this._authService.logout();
    this._router.routeReuseStrategy.shouldReuseRoute = () => false;
    this._router.onSameUrlNavigation = 'reload';
    window.location.href = '/sign-in';
  }

  public getLangText(lang) {
    if (lang == 'de') {
      return 'German';
    }
    else if (lang == 'fr') {
      return 'French';
    }
    else if (lang == 'ru') {
      return 'Russian';
    }
    else if (lang == 'tr') {
      return 'Turkish';
    }
    else {
      return 'English';
    }
  }

}
