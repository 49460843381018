import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnvironmentUrlService } from './environment-url.service';
import { Observable, Subject } from 'rxjs';
import { UserForRegistration, VerificationRequestModel } from 'src/app/interfaces/UserForRegistration.model';
import { UserForAuthentication } from 'src/app/interfaces/UserForAuthenticatio.model';
import { TokenStorageService } from './token-storage.service';



@Injectable({
    providedIn: 'root'
})

export class AuthService {
    constructor(private http: HttpClient,
        private _envUrl: EnvironmentUrlService,
        private token: TokenStorageService
    ) { }

    private createCompleteRoute = (route: string, envAddress: string) => {
        return `${envAddress}/${route}`;
    }

    login(route: string, body: UserForAuthentication): Observable<any> {
        return this.http.post(this.createCompleteRoute(route, this._envUrl.urlAddress), body, { responseType: 'json' });
    }

    register(route: string, body: UserForRegistration): Observable<any> {
        return this.http.post(this.createCompleteRoute(route, this._envUrl.urlAddress), body, { responseType: 'text' });
    }

    verifyUser(route: string, body: VerificationRequestModel): Observable<any> {
        return this.http.post(this.createCompleteRoute(route, this._envUrl.urlAddress), body, { responseType: 'text' });
    }
    public logout = () => {
       
        localStorage.removeItem("token");
        this.token.signOut();
        localStorage.removeItem('auth-token');
        localStorage.removeItem('auth-user');
        this.sendAuthStateChangeNotification(false);
    }

    private _authChangeSub = new Subject<boolean>()
    public authChanged = this._authChangeSub.asObservable();

    public sendAuthStateChangeNotification = (isAuthenticated: boolean) => {
        this._authChangeSub.next(isAuthenticated);
    }

}
