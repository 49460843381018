<mat-sidenav-container>
    <mat-sidenav [opened]="false" mode="over" #sidenav class="sidenav mat-elevation-z6">
        <button mat-icon-button color="warn" class="close" (click)="sidenav.close()">
            <mat-icon color="warn">close</mat-icon>
        </button>
        <div class="divider"></div>
        <app-sidenav-menu [menuItems]="sidenavMenuItems" [menuParentId]="0"></app-sidenav-menu>
        <div class="divider"></div>
    </mat-sidenav>

    <mat-toolbar color="primary" id="header-toolbar" class="topBarBackgroundColor" *ngIf="!isUserAuthenticated">
        <mat-toolbar-row fxLayoutAlign="space-between center" class="logo-toolbar theme-container custom-height">
            <div fxFlex.gt-sm="20">
                <a class="logo" routerLink="/" (click)="closeSubMenus()">
                    <img alt="Power 4 Students" [src]="'assets/images/Final_P4S.png'" style="width:55px !important;" />
                    Power 4 Students
                </a>
            </div>
            <div fxFlex.gt-sm="40" class="text-right" fxShow="false" fxShow.gt-sm>
                <!-- <a class="menuFontsize h5" mat-button routerLink="/">Home</a> -->
                <a class="btn btn-outline-light" style="font-weight: 500;border-width:1px" routerLink="/sign-in">Sign
                    In</a>
                <!-- <a class="menuFontsize" mat-button routerLink="/register">Student Register</a>  
                <a class="menuFontsize" mat-button routerLink="/bank-user/add-bank-user">Bank User Register</a> -->
                <!-- <a class="menuFontsize" mat-button routerLink="/contact">ContactUs</a> -->
            </div>
        </mat-toolbar-row>
    </mat-toolbar>

    <mat-toolbar color="primary" id="header-toolbar" class="topBarBackgroundColor" *ngIf="isUserAuthenticated">
        <mat-toolbar-row fxLayoutAlign="space-between center" class="top-toolbar theme-container">
            <span fxHide="false" fxHide.gt-sm>
                <button mat-button (click)="sidenav.toggle()" class="sidenav-toggle" style="background-color: green;">
                    <mat-icon>menu</mat-icon>
                </button>
            </span>
            <span fxShow="false" fxShow.gt-xs fxLayoutAlign="center center" class="callColor">
                <mat-icon class="mat-icon-sm">call</mat-icon>1234567890
            </span>
            <app-top-menu></app-top-menu>
        </mat-toolbar-row>
        <mat-toolbar-row fxLayoutAlign="space-between center" class="logo-toolbar theme-container custom-height">

            <div fxFlex.gt-sm="20">
                <a class="logo" routerLink="/" (click)="closeSubMenus()">
                    <img alt="Power 4 Students" [src]="'assets/images/Final_P4S.png'" class="lgclss" />
                </a>
            </div>
            <div fxFlex.gt-sm="30" fxShow="false" class="float-left" fxShow.gt-sm>
                <a class="menuFontsize" mat-button routerLink="/">Home</a>
                <a class="menuFontsize" mat-button routerLink="/admin">Dashboard</a>
                <a class="menuFontsize" mat-button routerLink="/products">Products</a>
            </div>

            <div fxFlex.gt-sm="30" fxShow="false" fxShow.gt-sm class="searchBar" *ngIf="isUserAuthenticated">
                <!-- <form method="get" class="search-form" fxLayout="row" *ngIf="isUserAuthenticated">
                    <button mat-raised-button [matMenuTriggerFor]="categoriesMenu"
                        #categoriesMenuTrigger="matMenuTrigger" type="button"
                        class="mat-elevation-z0 categories text-truncate text-muted">{{category?.name}}<mat-icon>
                            arrow_drop_down</mat-icon></button>
                    <mat-menu #categoriesMenu="matMenu" [overlapTrigger]="false" xPosition="before"
                        class="categories-dropdown">
                        <span (mouseleave)="categoriesMenuTrigger.closeMenu()">
                            <app-category-list [categories]="categories" [categoryParentId]="0"
                                (change)="changeCategory($event)"></app-category-list>
                        </span>
                    </mat-menu>
                    <input type="text" placeholder="Type to search..." id="searchText" fxFlex
                        (input)="globleSearchTextChange($event)">
                    <button mat-mini-fab (click)="search()" type="button"
                        class="search-btn mat-elevation-z0 text-muted">
                        <mat-icon>search</mat-icon>
                    </button>
                </form> -->
            </div>
            <div fxFlex.gt-sm="20" fxLayout="row" fxLayoutAlign="end center">
                <div fxLayoutAlign="center center" [@.disabled]="true">
                    <button mat-button [matMenuTriggerFor]="cartMenu" #cartMenuTrigger="matMenuTrigger"
                        *ngIf="isUserAuthenticated" class="flex-row-button">
                        <mat-icon class="mat-icon-lg">shopping_cart</mat-icon>
                        <span *ngIf="appService.Data.totalCartCount > 0"
                            class="cart-items-count">{{appService.Data.totalCartCount}}</span>
                        <div fxLayout="column" fxLayoutAlign="center center" fxShow="false" fxShow.gt-xs
                            class="top-cart">
                            <span>Shopping Cart</span>
                            <span *ngIf="appService.Data.totalCartCount > 0">
                                <bdi>{{appService.Data.totalCartCount}} item<span
                                        *ngIf="appService.Data.totalCartCount > 1">s</span> -
                                    Rs.<span>{{appService.Data.totalPrice | number : '1.2-2'}}</span>
                                </bdi>
                            </span>
                        </div>
                        <mat-icon class="mat-icon-sm">arrow_drop_down</mat-icon>
                    </button>
                    <mat-menu #cartMenu="matMenu" [overlapTrigger]="false" xPosition="before"
                        class="top-menu-dropdown cart-dropdown">
                        <ng-template matMenuContent>
                            <span (mouseleave)="cartMenuTrigger.closeMenu()" class="d-block">
                                <div fxLayout="row" fxLayoutAlign="space-between center" class="text-muted">
                                    <b><span>{{appService.Data.totalCartCount}} ITEM<span
                                                *ngIf="appService.Data.totalCartCount > 1">S</span></span></b>
                                    <b><a [routerLink]="['/cart']" class="text-muted">VIEW CART</a></b>
                                </div>
                                <div class="divider mt-1"></div>
                                <div *ngIf="appService.Data.totalCartCount == 0" class="py-1 text-muted text-center">You
                                    have no items in your shopping cart.</div>
                                <mat-list>
                                    <mat-list-item *ngFor="let product of appService.Data.cartList">
                                        <h3 matLine class="text-muted"> {{product.productName}} </h3>
                                        <p matLine class="text-muted">
                                            <!-- <bdi>{{product.cartCount}} x Rss.{{product.productImages | number :
                                                '1.2-2'}}</bdi> -->
                                        </p>
                                            <img *ngIf="product?.productImages?.length>0" [src]="product?.productImages[0]?.imageUrl" alt="image">

                                        <!-- <img [src]="product.productImages[0].imageUrl" alt="image"> -->
                                        <button mat-icon-button color="warn" class="remove"
                                            (click)="remove(product);stopClickPropagate($event)" matTooltip="Remove"
                                            matTooltipPosition="before">
                                            <mat-icon class="mat-icon-sm">close</mat-icon>
                                        </button>
                                    </mat-list-item>
                                </mat-list>
                                <div *ngIf="appService.Data.cartList.length > 0" class="cart-dropdown-footer">
                                    <div class="divider mt-1"></div>
                                    <div fxLayout="row" fxLayoutAlign="space-between center" class="text-muted mt-1">
                                        <b>TOTAL:</b>
                                        <b class="new-price">Rs.{{appService.Data.totalPrice | number : '1.2-2'}}</b>
                                    </div>
                                    <div class="divider mt-1"></div>
                                    <div fxLayout="row" fxLayoutAlign="space-between center" class="mt-1">
                                        <button mat-mini-fab color="warn" (click)="clear();stopClickPropagate($event)"
                                            matTooltip="Clear All" matTooltipPosition="after">
                                            <mat-icon>remove_shopping_cart</mat-icon>
                                        </button>
                                        <a [routerLink]="['/checkout']" mat-mini-fab color="primary"
                                            matTooltip="Checkout" matTooltipPosition="before">
                                            <mat-icon>check</mat-icon>
                                        </a>
                                    </div>
                                </div>
                            </span>
                        </ng-template>
                    </mat-menu>
                </div>
                <div fxLayoutAlign="center center" fxHide="false" fxHide.gt-sm [@.disabled]="true"
                    *ngIf="isUserAuthenticated">
                    <button mat-icon-button [matMenuTriggerFor]="searchMenu" #searchMenuTrigger="matMenuTrigger"
                        class="search-toggle-btn">
                        <mat-icon class="mat-icon-lg">search</mat-icon>
                    </button>
                    <mat-menu #searchMenu="matMenu" [overlapTrigger]="false" xPosition="before"
                        class="search-dropdown mat-elevation-z8">
                        <ng-template matMenuContent>
                            <form method="get" fxFlex class="search-form">
                                <button mat-raised-button [matMenuTriggerFor]="categories2Menu"
                                    #categories2MenuTrigger="matMenuTrigger" type="button"
                                    class="mat-elevation-z0 categories text-muted"
                                    (click)="stopClickPropagate($event)">{{category?.name}}<mat-icon>arrow_drop_down
                                    </mat-icon></button>
                                <mat-menu #categories2Menu="matMenu" [overlapTrigger]="false" xPosition="before"
                                    class="categories-dropdown">
                                    <ng-template matMenuContent>
                                        <span (mouseleave)="categories2MenuTrigger.closeMenu()">
                                            <app-category-list [categories]="categories" [categoryParentId]="0"
                                                (change)="changeCategory($event)"></app-category-list>
                                        </span>
                                    </ng-template>
                                </mat-menu>
                                <input type="text" placeholder="Type to search..." fxFlex
                                    (click)="stopClickPropagate($event)">
                                <button mat-mini-fab (click)="search()" type="button"
                                    class="search-btn mat-elevation-z0 text-muted">
                                    <mat-icon>search</mat-icon>
                                </button>
                            </form>
                        </ng-template>
                    </mat-menu>
                </div>
            </div>
        </mat-toolbar-row>
    </mat-toolbar>

    <div class="bgImage" *ngIf="isUserAuthenticated">&nbsp;
    </div>

    <div class="theme-container main">
        <app-breadcrumb></app-breadcrumb>
        <router-outlet></router-outlet>
    </div>

    <!-- <app-options></app-options> -->

    <app-footer *ngIf="isUserAuthenticated"></app-footer>

    <div *ngIf="showBackToTop" fxLayout="row" fxLayoutAlign="center center" class="back-to-top transition"
        (click)="scrollToTop()">
        <mat-icon>arrow_upward</mat-icon>
    </div>

</mat-sidenav-container>