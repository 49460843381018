
 <!--Navbar Start-->

 <!--Navbar End-->
 <!-- Welcome Area Start -->
 <section class="pelum-welcome-area fix" data-scroll-index="0">
    <!-- Shape Start -->
    <div class="shapes-container">
       <div class="bg-shape"></div>
    </div>
    <!-- Shape End -->
    <div class="container">
       <div class="row align-items-center">
          <div class="col-12 col-md-7">
             <div class="welcome-text">
                <h2 class="htext">Power 4 Students</h2>
                
                <div class="site-heading site-heading1">
                   <br>
                   <h4>Available Now</h4>
                   <h3 class="mtcls">Laptop Loans for Students</h3>
                   <span class="heading_overlay"></span>
                </div>

                <p>p4s.online (power for students) is an online portal to help students along their learning journey Laptops and the internet, are an essential part of the modern journey. A standard laptop including extended warranty and Internet connection can be acquired for as low as US$ 24 payable over 36 months. Other options available.</p>
             </div>
          </div>
          <div class="col-12 col-md-5">
             <div class="welcome-image">
                <img src="assets/images/user_laptop_ahnd.png" alt="welcome" class="animation-jump" />
             </div>
          </div>
       </div>
    </div>
 </section>
 <!-- Welcome Area End -->
 <!-- Features Area Start -->
 <section class="pelum-features-area" data-scroll-index="1">
   <div class="container">
      <div class="row">
         <div class="col-lg-12">
            <div class="site-heading">
               <h4>Obtain A Laptop</h4>
               <h2>In 4 Easy Steps</h2>
               <span class="heading_overlay"></span>
            </div>
         </div>
      </div>
      <div class="row">
         <div class="col-lg-3 col-md-6">
            <div class="single-feature wow fadeInLeft" data-wow-duration="2s" data-wow-delay="0.3s">
               <div class="feature-icon">
                  <i class="fa fa-pencil-square-o"></i>
               </div>
               <div class="feature-text">
                  <h3>[ 1 ]</h3>
                  <p>Register & fill required details</p>
               </div>
            </div>
         </div>
         <div class="col-lg-3 col-md-6">
            <div class="single-feature wow fadeInLeft" data-wow-duration="2s" data-wow-delay="0.5s">
               <div class="feature-icon">
                  <i class="fa fa-credit-card"></i>
               </div>
               <div class="feature-text">
                  <h3>[ 2 ]</h3>
                  <p>Obtain provisional credit limit</p>
               </div>
            </div>
         </div>
         <div class="col-lg-3 col-md-6">
            <div class="single-feature wow fadeInLeft" data-wow-duration="2s" data-wow-delay="0.8s">
               <div class="feature-icon">
                  <i class="fa fa-laptop"></i>
               </div>
               <div class="feature-text">
                  <h3>[ 3 ]</h3>
                  <p>Choose required products</p>
               </div>
            </div>
         </div>
         <div class="col-lg-3 col-md-6">
            <div class="single-feature wow fadeInLeft" data-wow-duration="2s" data-wow-delay="1.3s">
               <div class="feature-icon">
                  <i class="fa fa-file-text-o"></i>
               </div>
               <div class="feature-text">
                  <h3>[ 4 ]</h3>
                  <p>Submit required documents</p>
               </div>
            </div>
         </div>
      </div>
      <div class="row">
         <div class="col-lg-12">
            <div class="site-heading">
               <br>
               <hr>
            </div>
         </div>
      </div>
      <p class="ptx">Once your documents are verified, we will notify you by SMS and update your portal page with next steps to obtain your laptop.</p>
   </div>
</section>
<!-- Features Area End -->
<!-- Video Area Start -->
<section class="pelum-video-area">
   <div class="container">
      <div class="row">
         <div class="col-lg-12">
            <div class="video-box">
               <div class="video-inn">
                  
                  <div class="site-heading site-head">
                     <h4>Best Products</h4>
                     <h3 class="mtcls">Buy Now!</h3>
                     <span class="heading_overlay"></span>
                  </div>

                  <div class="row">
                     <div class="col-lg-12">
                        
                        <div class="product-slider owl-carousel wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.3s" >
                           <div class="product-item">
                              <div class="row">
                                 <div class="product-image col-lg-12">
                                    <img src="assets/images/sc1.png" alt="product" />
                                 </div>
                              </div>
                           </div>
                           <div class="product-item">
                              <div class="row">
                                 <div class="product-image col-lg-12">
                                    <img src="assets/images/sc2.png" alt="product" />
                                 </div>
                              </div>
                           </div>
                           <div class="product-item">
                              <div class="row">
                                 <div class="product-image col-lg-12">
                                    <img src="assets/images/sc3.png" alt="product" />
                                 </div>
                              </div>
                           </div>
                           <div class="product-item">
                              <div class="row">
                                 <div class="product-image col-lg-12">
                                    <img src="assets/images/sc4.png" alt="product" />
                                 </div>
                              </div>
                           </div>
                           <div class="product-item">
                              <div class="row">
                                 <div class="product-image col-lg-12">
                                    <img src="assets/images/sc5.png" alt="product" />
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</section>
<!-- Video Area End -->

<!-- Subscribe Area Start -->
<section class="pelum-subscribe-area">
   <div class="container">
      <div class="row">
         <div class="col-lg-12">
            <div class="site-heading wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.3s">
               <h4>About</h4>
               <h2>P4S. Online</h2>
               <span class="heading_overlay"></span>
            </div>
         </div>
      </div>
      <div class="row">
         <div class="col-lg-12">
            <div class="subscribe-box wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.3s">
               <div class="row">
                  <div class="col-lg-2">
                  </div>
                  <div class="col-lg-8">
                     <p class="ptx">P4s.online is a first of its kind portal for students seeking loans. Students can view, apply and track applications, anytime, anywhere by accessing the portal. Our aim is to minimize money constraint hindrances in learning.</p>
                  </div>
                  <div class="col-lg-2">
                  </div>
            </div>
         </div>
      </div>
   </div>
</div>
</section>
<!-- Subscribe Area End -->
<!-- Contact Area Start -->
<section class="pelum-contact-area" data-scroll-index="6">
   <div class="container">
      <div class="row">
         <div class="col-lg-12">
            <div class="site-heading wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.3s">
               <h4>Why Wait ?</h4>
               <h2>Let's Apply</h2>
               <span class="heading_overlay"></span>
            </div>
         </div>
      </div>
      <div class="row">
         <div class="col-lg-2">
         </div>
         <div class="col-lg-8">
            <div class="contact-form wow fadeInLeft" data-wow-duration="2s" data-wow-delay="0.5s">
               <form>
                  <div class="row">
                     <div class="col-12 col-md-6">
                        <p>
                           <input class="form-control" type="text" placeholder="Name" />
                        </p>
                     </div>
                     <div class="col-12 col-md-6">
                        <p>
                           <input class="form-control" type="text" placeholder="Qualification" />
                        </p>
                     </div>
                  </div>
                  <div class="row">
                     <div class="col-lg-6">
                        <p>
                           <input class="form-control" type="email" placeholder="E-mail" />
                        </p>
                     </div>
                     <div class="col-lg-6">
                        <p>
                           <input class="form-control" type="tel" placeholder="Mobile" />
                        </p>
                     </div>
                  </div>
                  <div class="row">
                     <div class="col-lg-12">
                        <p>
                           <textarea class="form-control" placeholder="Your Message..."></textarea>
                        </p>
                     </div>
                  </div>
                  <div class="row">
                     <div class="col-lg-12">
                        <p class="txtc">
                           <button type="submit" class="pelum-btn">Apply Now <span></span></button>
                        </p>
                     </div>
                  </div>
               </form>
            </div>
         </div>
         <div class="col-lg-2">
         </div>
      </div>
   </div>
</section>
<!-- Contact Area End -->

 <!-- Footer Area Start -->
 <footer class="pelum-footer-area">
   <div class="ocean">
      <div class="wave"></div>
      <div class="wave"></div>
   </div>
   <div class="container">
      <div class="row">
         <div class="col-md-12">
            <div class="footer-box">
               <div class="footer-logo wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.3s">
                  <a href=""><img src="assets/images/Final_P4S.png" class="flogo" alt="footer logo" /></a>
                  <br>
                  <p class="ftxt">Power 4 Students</p>
               </div>
               <div class="footer-copyright wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.5s">
                  <p>Copyright &copy; P4S 2022.</p>
               </div>
               <div class="footer-social">
                  <ul>
                     <li class="wow fadeInLeft" data-wow-duration="2s" data-wow-delay="0.3s"><a href="#"><i class="fa fa-facebook"></i></a></li>
                     <li class="wow fadeInLeft" data-wow-duration="2s" data-wow-delay="0.5s"><a href="#"><i class="fa fa-twitter"></i></a></li>
                     <li class="wow fadeInLeft" data-wow-duration="2s" data-wow-delay="0.7s"><a href="#"><i class="fa fa-linkedin"></i></a></li>
                     <li class="wow fadeInLeft" data-wow-duration="2s" data-wow-delay="0.9s"><a href="#"><i class="fa fa-youtube"></i></a></li>
                  </ul>
               </div>
            </div>
         </div>
      </div>
   </div>
</footer>
<!-- Footer Area End -->

