export class Category {
  constructor(public id: number,
    public name: string,
    public hasSubCategory: boolean,
    public parentId: number,
    public categoryId: number,
    public categoryTitle: string,
    public categoryDescription: string,

  ) { }
}

export class Product {
  constructor(public id: number,
    public name: string,
    public images: Array<any>,
    public productImages: Array<any>,
    public oldPrice: number,
    public newPrice: number,
    public discount: number,
    public ratingsCount: number,
    public ratingsValue: number,
    public description: string,
    public details: string,
    public availibilityCount: number,
    public cartCount: number,
    public color: Array<string>,
    public size: Array<string>,
    public weight: number,
    public categoryId: number,
    public productId: number,
    public price: number,
    public loggedUserId: number,
    public productName: string) { }
}
export class SearchProduct {
  constructor(public productName: string,
    public categoryName: string,
    public minValue: number,
    public maxValue: number,
  ) { }
}

export class Image {
  constructor(public link: string,
    public preview: string
  ) { }
}

export class Order {
  constructor(
    public userId: number,    
    public orderItems: Array<any>,
    public orderTotal: number,
    public productTotal: number
  ) { }
}
export class OrderList {
  constructor(
    public userId: number,  
    public userName: string,
    public orderItems: Array<any>,
    public orderTotal: number,
    public productTotal: number,
    public orderDate: Date,   
    ) { }
}
export class StudentModel {
  constructor(
    //student Info  
    public studentId:number,   
    public title: string,
    public firstName: string,
    public middleName: string,
    public lastName: string,
    public fatherFName: string,
    public fatherMName: string,
    public fatherLName: string,
    public mobileNo: string,
    public emailId: string,
    public dateofBirth: Date,
    public categoryId: number,
    public gender: number,
    public maritalStatus: number,
    public occupation: string,
    public qualification: string,
    public marksInHighestQualification: number,
    public incomeFromAllResources: number, 
    public panNo: string,  
    public pin:string,     
    public cityId:number,
    public stateId: number,
    public addressLine1: string,
    public addressLine2: string,   
    //personalInfo   
    public parentTitle:string,
    public parentHusbandFName:string,
    public parentHusbandMName:string,
    public parentHusbandLName:string,  
    public parentDateofBirth:string,
    public parentCategoryId:string,
    public parentGender:number,
    public parentMaritalStatus:string,
    public parentOccupation:string,
    public parentQualification:string,
    public parentMarksInHighestQualification:string,   
    public parentPANNo:string,
    public parentStateId:string,
    public parentAddressLine1:string,
    public parentAddressLine2:string,   
    public parentCityId:number,
    public parentMobileNo:string,
    public parentEmailId:string,
    public parentPIN:string, 
    //BasicInfo   
    public isPursuingHigherStudyIn:boolean,  
    public parentalIncome1:number, 
    public isAdmittedValidCourse:boolean, 
    //Student PresentBanker Details      
    public studentBankName:string, 
    public studentBranchName:string, 
    public studentBranchIFSC:string, 
    public studentAccountType:string, 
    public studentAccountNumber:number,     
    public studentWheatherRelatedToBank:boolean,    
     //Parent PresentBanker Details      
     public parentBankName:string, 
     public parentBranchName:string, 
     public parentBranchIFSC:string, 
     public parentAccountType:string, 
     public parentAccountNumber:number,      
     public parentWheatherRelatedToBank:boolean,     
     //Course Details
     public managementQuota: string,
     public durationOfCourse: string,
     public institutionName: string,
     public courseName: string,
     public dateOfCommencement: Date,
     public dateOfCompletion: Date, 
    ) { }
 
}
export class BankModel {
  constructor(
    //public bankId: number,  
    public bankCode: number,
    public bankName: string,
    public branchName: string,
    public cityId: number,
    public city:string,
    public bankAddress: string,
    public ifscCode: string,
    public isActive: boolean,
     ) { }    
}
export class BankUserModel {
  constructor(
    //public bankUserId:number,    
    public bankId: number, 
    public bankName:string, 
    public contactPerson: number,
    public contactPersonMobileNo: string,
    public contactPersonEmail: string,  
    public userId: string,
    public isActive: boolean,
     ) { }       
   
}
export class SupplierDetailModel {
  constructor(
    public supplierId: number,
    public supplierName: string,
    public contactPerson: string,
    public emailId: string,
    public contactNo: string,    
    public isActive: boolean,
    public userId: number,
    public password: string,
    public address: string,
     ) { }       
   
}
export class SchemeList {
  constructor(
    public orderId:number,
    public loanSchemeId: number,  
    public loanSchemeName: string,   
    public bankId: number,
    public loanDescriptionFile: string, 
    public  apply: boolean,   
    ) { }
}
export class OrderSchemeModel {
  constructor(
    public orderId: number,  
    public schemeItems: Array<any>,    
    ) { }
}


