<div class="main-slider">
  <div class="swiper-container h-100 corners" [swiper]="config">
    <div class="swiper-wrapper h-100">
      <div *ngFor="let slide of slides" class="swiper-slide">
        <div class="slide-item swiper-lazy">
          <div class="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
          <div class="mask"></div>
          <div fxLayout="column" fxLayoutAlign="center center" class="content">
            <img [src]="slide.image">
            <h1>{{slide.title}}</h1>
            <h3>{{slide.subtitle}}</h3>
            <!-- <button mat-raised-button color="primary">Shop now</button> -->
          </div>
        </div>
      </div>
    </div>
    <div class="swiper-pagination white"></div>
    <button mat-mini-fab color="primary" class="swiper-button-prev swipe-arrow">
      <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
    <button mat-mini-fab color="primary" class="swiper-button-next swipe-arrow">
      <mat-icon>keyboard_arrow_right</mat-icon>
    </button>
  </div>
</div>