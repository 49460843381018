import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class GlobalSearchService {
    public filteredCategory: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    public filteredProduct: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    constructor() { }
}