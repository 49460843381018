<div fxLayout="row" fxLayout.xs="column" [fxLayoutAlign]="align" class="text-muted">
    <div *ngIf="product?.availibilityCount > 0 && (type=='all' || type=='wish')">
        <bdi>
            <span *ngIf="type!='wish'" class="fw-500">Quantity:</span>
            <button mat-icon-button matTooltip="Remove" (click)="decrement()">
                <mat-icon>remove</mat-icon>
            </button>&nbsp;&nbsp;&nbsp;&nbsp;
            <span>{{count}}</span>
            <button mat-icon-button matTooltip="Add" (click)="increment()">
                <mat-icon>add</mat-icon>
            </button>
        </bdi>
    </div>
    <div *ngIf="type!='wish'">
        <!-- <button mat-icon-button matTooltip="Add to wishlist" (click)="addToWishList(product)"><mat-icon>favorite</mat-icon></button> -->
        <!-- <button mat-icon-button matTooltip="Add to cart" *ngIf="product?.availibilityCount > 0"
            (click)="addToCart(product)">
            <mat-icon>shopping_cart</mat-icon>
        </button> -->
        <span class="product-grid__btn product-grid__add-to-cart" matTooltip="Add to cart"
            *ngIf="product?.availibilityCount > 0" (click)="addToCart(product)">
            <mat-icon class="align-top">shopping_cart</mat-icon><span>Add to cart</span>
        </span>
        <!-- <button mat-icon-button matTooltip="Add to compare" (click)="addToCompare(product)"><mat-icon>compare</mat-icon></button> -->
        <!-- <button *ngIf="type!='all'" mat-icon-button matTooltip="Quick view" (click)="openProductDialog(product)">
            <mat-icon>remove_red_eye</mat-icon>
        </button> -->
        <span *ngIf="type!='all'" class="product-grid__btn product-grid__view" matTooltip="Quick view"
            (click)="openProductDialog(product)">
            <mat-icon class="align-top">remove_red_eye</mat-icon>
            <span>View more</span>
        </span>
    </div>
</div>
<div *ngIf="product?.availibilityCount == 0 && type=='all'" class="bg-warn p-1 mt-2">
    Sorry, this item is unavailable. Please choose a different one.
</div>